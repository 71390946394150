import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry
    },

    state: {
        formAb: 'A',
        ticketsAb: 'A',
        endTime: '2020-04-6'
    },

    getters: {
        getFormAb: state => state.formAb,
        getTicketsAb: state => state.ticketsAb,

        endTime: state =>
        {
            return state.endTime;
        },

        v2: state =>
        {
            let endTime = new Date( state.endTime );
            let now = new Date();

            return now > endTime;
        }
    },

    mutations: {
        setABForm: ( state, payload ) =>
        {
            // console.log( 'form:', payload );
            state.formAb = payload;
        },

        setABTickets: ( state, payload ) =>
        {
            // console.log( 'ticket:', payload );
            state.ticketsAb = payload;
        }
    },

    actions: {
        determineFormAB: ( context, isMobile ) =>
        {
            let rand = Math.random();
            if( rand > 0.5 )
            {
                context.commit( 'setABForm', 'B' );
            }
        },

        determineTicketAB: ( context, payload ) =>
        {
            let rand = Math.random();
            if( rand > 0.5 )
            {
                context.commit( 'setABTickets', 'B' );
            }
        },

        setTicketAB: ( context, payload ) =>
        {
            context.commit( 'setABTickets', payload );
        }
    }
});
