import 'babel-polyfill';

import Vue from 'vue';
import App from './App';

import router from '@/app/router';
import VuexRouterSync from 'vuex-router-sync';
import VeeValidate from 'vee-validate';
import dict from 'vee-validate/dist/locale/nl';
import attr from '@/app/i18n';

import store from '@/app/store';
import Axios from 'axios';

VeeValidate.Validator.extend( 'date_valid', {
    getMessage: field => 'The ' + field + ' value is not truthy.',
    validate: value =>
    {
        let parts = value.split( '-' );
        let day = parseInt( parts[ 2 ] );
        let month = parseInt( parts[ 1 ] - 1 );
        let year = parseInt( parts[ 0 ] );
        let date = new Date( year, month, day, 0, 0, 0 );

        let before = new Date();
        before.setYear( 2002 );

        return ( before - date >= 0 ) && day === date.getDate() && month === date.getMonth() && year === date.getFullYear();
    }
});

VeeValidate.Validator.extend( 'iban_valid', {
    getMessage: field => 'The ' + field + ' value is not valid.',
    validate: value =>
    {
        let alphabet = [ 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z' ];
        let parts = value.match( /([A-Z\d]{4,4})([A-Z]{4,4})(\d{10})/i );
        let checkString = parts[2] + parts[3] + parts[1];
        let modString = '';

        for( let i = 0; i < checkString.length; i++ )
        {
            if( alphabet.indexOf( checkString[i].toLowerCase() ) >= 0 )
            {
                modString += 10 + alphabet.indexOf( checkString[i].toLowerCase() );
            }
            else
            {
                modString += checkString[i];
            }
        }

        while( modString.length > 2 )
        {
            let block = modString.slice( 0, 9 );
            modString = parseInt( block, 10 ) % 97 + modString.slice( block.length );
        }

        if( modString.length === 2 && parseInt( modString, 10 ) % 97 !== modString )
        {
            modString = '' + parseInt( modString, 10 ) % 97;
        }

        return ( modString === '1' || modString === '01' );
    }
});

Vue.use( VeeValidate, {
    events: 'blur'
});

VeeValidate.Validator.localize( 'nl', Object.assign( dict, attr.nl ) );

VuexRouterSync.sync( store, router );
Axios.defaults.baseURL = window._rootData.apiUrl;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use(
    response => response,
    ( error ) =>
    {
        return Promise.reject( error );
    }
);

Vue.$http = Axios;
Object.defineProperty( Vue.prototype, '$http', {
    get()
    {
        return Axios;
    }
});

window.dataLayer = window.dataLayer || [];
Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
});
