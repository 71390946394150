import Vue from 'vue';
import Router from 'vue-router';
import store from '@/app/store';
import { TweenMax } from '@/vendor';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/bedankt',
            name: 'Confirmed',
            component: () =>
            {
                return import( /* webpackChunkName: "confirmed" */ '@/app/pages/Confirmed' );
            },
            meta: {
                pageType: 'promotion-confirmation'
            }
        },
        {
            path: '/overzicht',
            name: 'Overview',
            component: () =>
            {
                return import( /* webpackChunkName: "overview" */ '@/app/pages/Overview' );
            },
            beforeEnter: ( to, from, next ) =>
            {
                if( store.getters[ 'entry/get' ]( ['id'] ).id )
                {
                    window.location.reload();
                }
                next();
            },
            meta: {
                pageType: 'promotion-leadform'
            }
        },
        {
            path: '/',
            alias: [ '/winnen', '/wincode', '/code', '/win' ],
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            },
            meta: {
                pageType: 'promotion-landing'
            }
        },
        {
            path: '/loten',
            name: 'Tickets',
            component: () =>
            {
                return import( /* webpackChunkName: "tickets" */ '@/app/pages/Tickets' );
            },
            meta: {
                pageType: 'promotion-leadform'
            }
        },
        {
            path: '/form2',
            name: 'Naw2',
            component: () =>
            {
                return import( /* webpackChunkName: "naw2" */ '@/app/pages/Naw2' );
            }
        },
        {
            path: '/form',
            name: 'Naw',
            component: () =>
            {
                return import( /* webpackChunkName: "naw" */ '@/app/pages/Naw' );
            },
            children: [
                {
                    path: '1',
                    name: 'Form1',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form1' */ '@/app/pages/naw/Form1' );
                    },
                    meta: {
                        pageType: 'promotion-leadform'
                    }
                },
                {
                    path: '2',
                    name: 'Form2',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form2' */ '@/app/pages/naw/Form2' );
                    },
                    meta: {
                        pageType: 'promotion-leadform'
                    }
                },
                {
                    path: '3',
                    name: 'Form3',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form3' */ '@/app/pages/naw/Form3' );
                    },
                    meta: {
                        pageType: 'promotion-leadform'
                    }
                }
            ]
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( to.path === '/bedankt' )
    {
        let entry = store.getters['entry/get']();

        // dataLayer.push({
        //     event: 'pageview',
        //     origin: 'website',
        //     type: to.meta.pageType,
        //     virtualPage: to.fullPath,
        //     aantalLoten: entry.tickets.length
        // });

        dataLayer.push({
            event: 'confirmation',
            newsletter_optin: entry.kit
            // nyx_id: '1234'
        });
    }
    else
    {
        // dataLayer.push({
        //     event: 'pageview',
        //     origin: 'website',
        //     type: to.meta.pageType,
        //     virtualPage: to.fullPath
        // });

        let formType = store.getters[ 'getTicketsAb' ];
        // let lotSuffix = '';

        if( formType === 'B' )
        {
            // lotSuffix = '-2';
        }

        let trackingAlias = {
            '/': '/landing',
            '/loten': '/loten',
            '/form/1': ( formType === 'A' ? '/gegevens' : '/gegevens-1' ),
            '/form/2': '/gegevens-2',
            '/form/3': '/gegevens-3',
            '/overzicht': '/overzicht',
            '/bedankt': '/bedankt'
        };

        dataLayer.push({
            event: 'pageview',
            page_path: trackingAlias[ to.fullPath ]
        });
    }

    TweenMax.to( 'html, body', 0.5, { scrollTop: 0 });

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
