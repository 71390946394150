<template>
    <div :class="{ 'main-layout': true, [ ( $route.name ? $route.name.toLowerCase() : '' ) + '-route']: true }">
        <div class="top-wrapper">
            <!-- <img class="confetti" src="/static/img/layout-confetti.png" /> -->

            <transition @enter="onLogoEnter" @leave="onLogoLeave">
                <div class="lotto-logo" v-show="isLogoRoute"><a href="https://lotto.nederlandseloterij.nl/" target="_blank"><img src="/static/img/logos/lotto.png" /></a></div>
            </transition>

            <div class="sound-toggle" @click="toggleSound">
                <img :src="'/static/img/logos/' + ( muted ? 'muted' : 'unmuted' ) + '.svg'" />
            </div>
        </div>
        <div class="content">
            <slot></slot>
        </div>

        <transition @enter="onFooterEnter" @leave="onFooterLeave" >
            <footer v-show="isFooterRoute">
                <div class="content-wrapper">
                    <img src="/static/img/logos/footer.png" />
                    <p class="disclaimer small">Voor het organiseren van Lotto is aan Lotto bv vergunning verleend door de Kansspelautoriteit, trekking Laan van Hoornwijck 55 te Rijswijk.<br />Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a @click="trackAv" :href="'/static/pdf/actievoorwaarden' + ( isV2 ? '2' : '' ) + '.pdf'" target="_blank">actievoorwaarden</a>. | <a @click="trackPrivacyClickout" href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy Statement</a> Nederlandse Loterij</p>
                </div>
            </footer>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { TweenMax } from '@/vendor';
    import { tracking } from '@/app/mixins';

    export default {
        mixins: [ tracking ],

        data()
        {
            return {
                muted: true,
                hideLogoOn: [],
                hideFooterOn: []
            };
        },

        computed: {
            isLogoRoute()
            {
                return this.hideLogoOn.indexOf( this.$route.name ) === -1;
            },

            isFooterRoute()
            {
                return this.hideFooterOn.indexOf( this.$route.name ) === -1;
            },

            isV2()
            {
                return this.$store.getters[ 'v2' ];
            }
        },

        methods: {
            toggleSound()
            {
                this.muted = !this.muted;
                createjs.Sound.muted = this.muted;
            },

            trackPrivacyClickout()
            {
                this.trackEvent( 'Klik op link - Privacy Statement', 'Link tracking', 'Nieuw venster - Privacy Statement - https://www.nederlandseloterij.nl/privacy' );
            },

            trackAv()
            {
                this.trackEvent( 'Klik op link - Actievoorwaarden', 'Link tracking', window.location.origin + '/static/pdf/actievoorwaarden.pdf' );
            },

            onBuisEnter()
            {
                TweenMax.from( this.$el.querySelector( '.buis' ), 0.3, { y: '-200px', delay: 8 });
            },

            onLogoEnter()
            {
                TweenMax.fromTo( '.lotto-logo', 0.3, { x: '-200px' }, { x: 0, delay: 0.1 });
            },

            onLogoLeave( el, done )
            {
                if( this.$route.name === 'Landing' )
                {
                    done();
                }
                else
                {
                    TweenMax.fromTo( '.lotto-logo', 0.3, { x: '0px' }, { x: '-200px', onComplete: done });
                }
            },

            onFooterEnter()
            {
                TweenMax.fromTo( 'footer .content-wrapper', 0.3, { y: '200px' }, { y: 0, delay: 0.1 });
                TweenMax.fromTo( 'footer', 0.3, { overflow: 'hidden' }, { overflow: 'visible', delay: 0.1 });
            },

            onFooterLeave( el, done )
            {
                TweenMax.set( 'footer', { position: 'absolute', bottom: '0', overflow: 'hidden' });
                TweenMax.fromTo( 'footer .content-wrapper', 0.3, { y: '0px' }, {
                    y: '200px',
                    onComplete: () =>
                    {
                        done();
                        TweenMax.set( 'footer', { position: 'static', overflow: 'visible' });
                    }
                });
            }
        }
    };
</script>
