import EntryProxy from '@/app/proxies/EntryProxy';
import IbanProxy from '@/app/proxies/IbanProxy';
import MailDomainProxy from '@/app/proxies/MailDomainProxy';
// import CodeProxy from '@/app/proxies/CodeProxy';

/* eslint-disable */
export default {
    namespaced: true,
    state: {
        entry: {
            tickets:[],
            code: null
            /* city:"Hilversum",
            date_of_birth:"1995-05-07",
            emailaddress:"test@test.nl",
            fingerprint:"454d6650a7f7e2cf75bef9e4ca9ded12",
            firstname:"Test",
            gender:"male",
            house_num:"66",
            house_num_add:null,
            iban:"NL40ASRB0738442909",
            infixname:"ten",
            kit:false,
            street:"Noorderweg",
            surname:"Tester",
            telephone:null,

            xl:true,
            zipcode:"1221AB" */
        }
    },

    getters: {
        get: state => ( values ) =>
        {
            return state.entry;
        },

        code: state => state.entry.code,

        getNumbers: state => {
            if( state.entry.tickets )
            {
                return state.entry.tickets;
            }

            return [];
        }
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        },

        setTickets: ( state, payload ) =>
        {
            state.entry['tickets'] = payload;
        },

        setActiecode: ( state, payload ) =>
        {
            state.entry['code'] = payload;
        }
    },

    actions: {
        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        setNumbers: ( context, payload ) =>
        {
            let numbers = [];
            for( let ticket of payload )
            {
                let processedNumbers = [];
                ticket.numbers.forEach( number => processedNumbers.push( number.toString().padStart( 2, '0' ) ) );
                numbers.push( processedNumbers );
            }

            context.commit( 'setTickets', numbers );
        },

        save: ( context ) =>
        {
            context.commit( 'assign', { prize: 10 } );

            return new EntryProxy()
                .create( context.getters.get() )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        checkCode: ( context, payload ) =>
        {
            let codes = [
                'LJB', 'LMP', 'LT', 'LVD', 'LVV', 'LNG', 'LP', 'LM', 'LLP', 'LCR', 'LCT', 'LCD', 'LA', 'HH', 'HA', 'LF'
            ];

            let found = codes.find( item =>
            {
                return payload.indexOf( item ) === 0;
            });

            if( found )
            {
                return context.commit( 'setActiecode', payload );
            }
            else
            {
                return Promise.resolve( { correct: false } );
            }
            /*
            return new CodeProxy()
                .find( payload )
                .then( response =>
                {
                    console.log( 'code response', response );
                    if( response.correct === true )
                    {
                        context.commit( 'setActiecode', payload );
                    }

                    return response;
                })
                .catch( error =>
                {
                    console.log( 'code error', error );
                    return Promise.reject( error );
                }) */
        },

        checkIban: ( context, payload ) =>
        {
            // payload = { iban: <iban> }

            return new IbanProxy()
                .create( payload )
                .then( response =>
                {
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        checkMailDomain: ( context, payload ) =>
        {
            // payload = { emailaddress: <emailaddress> }

            return new MailDomainProxy()
                .create( payload )
                .then( response =>
                {
                    return response;
                })
                .catch( error =>
                {
                    return error;
                    // return Promise.reject( error );
                });
        }
    }
};
