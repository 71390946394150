export default {
    attributes: {
        firstname: 'Voornaam',
        infixname: 'Tussenv.',
        surname: 'Achternaam',
        zipcode: 'Postcode',
        house_num: 'Huisnr.',
        house_num_add: 'Toev.',
        telephone: 'Telefoonnummer',
        telephoneoptin: 'Deze checkbox',
        emailaddress: 'E-mailadres',
        date_of_birth: 'Geboortedatum',
        kit: 'Deze checkbox',
        terms: 'Deze checkbox',
        iban: 'Iban',
        machtiging: 'Deze checkbox',
        street: 'Straatnaam',
        city: 'Woonplaats'
    },
    custom: {
        age: {
            required: 'Deze checkbox is verplicht.'
        }
    }
};
